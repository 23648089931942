/*

    This is common table.
    The purpose of this table is to wrap table, local search and local pagination into one component.

    Accepted properties:
        - columns: column definition for table
        - data: data to be used on table
        - paginationOption: options to be used by pagination. paginationOption={null} will disable the pagination
        - tableWrapperClass: classes to be used for table wrapper
        - searchWrapperClass: classes to be used for search input wrapper
        - searchInputClass: classes to be used for search input
        - tableClass: classes to be used for table
        - tableHeaderClass: classes to be used to table header
        - other options default from react-bootstrap-table-next, such as defaultSorted, bordered, etc

*/


import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import classes from './Table.module.css';
import { useTranslation } from 'react-i18next';


const SearchItem = (props) => {

    let input;
    const {t} = useTranslation()
    const searchInputClass = `form-control pega-form-input-text ${props.searchInputClass ? classes['search-input'] + ' ' + props.searchInputClass : classes['search-input']}`

    const handleChange = (event) => {
        props.onSearch(event.target.value.toLowerCase());
    };

    return (
        <input
            className={searchInputClass}
            ref={ n => input = n }
            type="text"
            onChange={(e)=>handleChange(e)}
            placeholder={t('common.search')}
        />
    );

};

const EmptyDataRow = () => {
    return <div className={classes['empty-data-Row']}>No Data</div>;
}

const Table = (componentProps) => {

    const sizePerPageList = componentProps.sizePerPageList ? componentProps.sizePerPageList : [10, 30, 50]
    const sizePerPage = componentProps.sizePerPage ? componentProps.sizePerPage : sizePerPageList[0]

    const defaultOptionPagination = {
        sizePerPage: sizePerPage,
        sizePerPageList: sizePerPageList,
        onPageChange: componentProps.onPaginationChanged,
        onSizePerPageChange: componentProps.onSizePerPageChange
    }

    const optionPagination = componentProps.paginationOption ? {...defaultOptionPagination, ...componentProps.paginationOption} : componentProps.paginationOption === null ? null : defaultOptionPagination

    const tableWrapperClass = `${componentProps.tableWrapperClass ? classes['table--wrapper'] + ' ' + componentProps.tableWrapperClass : classes['table--wrapper']}`
    const searchWrapperClass = `${componentProps.searchWrapperClass ? classes['search-input--wrapper'] + ' ' + componentProps.searchWrapperClass : classes['search-input--wrapper']}`

    const tableClass = `${componentProps.tableClass ? 'pega-table ' + componentProps.tableClass : 'pega-table'}`
    const tableHeaderClass = `${componentProps.tableHeaderClass ? 'bootstraptable__headercolor text-center' + ' ' + componentProps.tableHeaderClass : 'bootstraptable__headercolor text-center'}`

    const controllerWrapperClass = `${componentProps.controllerWrapperClass ? classes['controller--wrapper'] + ' ' + componentProps.controllerWrapperClass : classes['controller--wrapper']}`
    const controllerContentClass = `${componentProps.controllerContentClass ? classes['controller--content'] + ' ' + componentProps.controllerContentClass : classes['controller--content']}`

    return (
        <React.Fragment>
            <ToolkitProvider
                keyField={componentProps.keyField}
                columns={componentProps.columns}
                data={componentProps.data}
                search
                bootstrap4
            >
                { props => (

                    <div className={tableWrapperClass}>
                        { (componentProps.localSearch !== false || componentProps.controller) &&

                            <div className={controllerWrapperClass}>
                                <div className={controllerContentClass}>
                                    {componentProps.controller &&
                                        <componentProps.controller {...componentProps.controllerProps} />
                                    }
                                </div>

                                <div className={searchWrapperClass}>
                                {
                                    componentProps.localSearch !== false &&

                                        <SearchItem
                                            { ...props.searchProps}
                                            searchInputClass={componentProps.searchInputClass ? componentProps.searchInputClass : ''}
                                        />

                                }
                                </div>
                            </div>
                        }

                        <BootstrapTable

                            classes={tableClass}
                            headerClasses={tableHeaderClass}
                            pagination={ optionPagination != null ? paginationFactory(optionPagination) : null}
                            noDataIndication={EmptyDataRow}
                            { ...props.baseProps }
                            {...componentProps}
                        />

                    </div>

                )}
            </ToolkitProvider>
        </React.Fragment>
    )
}

export default Table;
