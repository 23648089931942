import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Serving_Button_with_Modal from "./Serving_Button_with_Modal";
import { http_restart_serving_service } from "../../api/model";
import toastr from 'toastr';


const Serving_Button_Sevice_Restart = (props) => {
    const {update_table_fn, reset_table_fn} = props;
    const {t} = useTranslation("serving");

    const [is_ready, set_is_ready] = useState(true);

    const submit_handler = () => {
        set_is_ready(false);

        const timeout = 2 * 1000;
        const controller = new AbortController()
        let timeout_id = setTimeout(() => controller.abort(), timeout)

        http_restart_serving_service(controller.signal)
            .then(response => {
                clearTimeout(timeout_id);
                return response.text();
            })
            .then(resp_text => {
                set_is_ready(true);

                let error_message = 'Fail';
                if (resp_text.includes('pods is forbidden'))
                    error_message += ' by no permission'

                console.error('Restart Service: ', error_message, resp_text);
                toastr.error(error_message, "Restart Service");
            })
            .catch(error => {
                set_is_ready(true);
                update_table_fn();
                reset_table_fn();

                toastr.success("Success");
            });
    }

    return (<>
        <Serving_Button_with_Modal
            title={t('restart_service')}
            submit_handler={submit_handler}
            is_enabled={is_ready}
        >
            {t('confirm')} <span style={{textTransform: 'lowercase'}}> {t('restart_service')}</span>..?
            <br />
            <span style={{color: 'gray', fontSize: 'small'}}>
            (Note: {t('auto_refresh_after_restart')})
            </span>
        </Serving_Button_with_Modal>

        {!is_ready &&
            <FontAwesomeIcon style={{color: 'white', marginLeft: '5px'}} size="1x" icon={solid('spinner')} spin />
        }
    </>)
}

export default Serving_Button_Sevice_Restart;
