import { ServerURL, ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

export const http_get_instance_info = async () => {
  const options = getDefaultOptions();
  const url =
    ServerURL + `/api/v1/p/model_mgr/serving/instance_info?broadcast=true`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const http_get_model_template_info = async () => {
  const options = getDefaultOptions();

  const url =
    ServerURL +
    `/api/v1/p/model_mgr/serving/query_on_disk_models?broadcast=true`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const http_get_model_info = async () => {
  const options = getDefaultOptions();
  const url =
    ServerURL +
    `/api/v1/p/model_mgr/serving/query_on_disk_models?broadcast=true`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const http_restart_serving_service = (signal) => {
  const options = getDefaultOptions();
  const url = ServerURL + `/api/v1/p/model_mgr/serving/restart`;

  return fetch(url, { ...options, signal }); // fetch_promise
};

export const http_set_model_status = async (request_data, is_online) => {
  const options = getDefaultOptions();

  let url = ServerURL + `/api/v1/p/model_mgr/serving/prepare_model`;
  if (!is_online)
    url =
      ServerURL +
      `/api/v1/p/model_mgr/serving/unload?b_rmtree=false&broadcast=false`;

  options.headers = { ...options.headers, ...request_data };
  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return returnResponse(response);
};

export const http_model_lock = async (project_token, model_name, is_lock) => {
  const options = getDefaultOptions();
  let method = 'PUT';
  if (!is_lock) method = 'DELETE';

  const url =
    ServerURL +
    `/api/v1/p/model_mgr/serving/protected_model/${project_token}/${model_name}?broadcast=true`;
  const response = await fetch(url, {
    ...options,
    method,
  });

  return returnResponse(response);
};

export const httpGetSubmodels = async (payload) => {
  const options = getDefaultOptions();

  const { token, submodel, version } = payload;

  let url = `${ServerAPIPrefix}pipelineProxy/model/getModelList?token=${token}`;

  if (submodel) {
    url += `&model_name=${submodel}`;
    if (version) url += `&version=${version}`;
  }
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetModel = async (payload) => {
  const options = getDefaultOptions();
  const { token, version } = payload;

  let url = `${ServerAPIPrefix}models/${token}`;
  if (version) {
    url += `?_version=${version}`;
  }

  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpGetTaskAndWeights = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${payload.token}/tasksandweight?modelname=${payload.modelName}&sync=${payload.sync}`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpGetModelDetail = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}pipelineProxy/model/detail?token=${payload.token}&modelName=${payload.modelName}&version=${payload.modelVersion}`;

  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetModelReplayResult = async (expIds) => {
  const options = getDefaultOptions();

  const params = new URLSearchParams();
  expIds.forEach((id) => {
    params.append('experiment_id', id);
  });
  const url = `${ServerAPIPrefix}replayProxy/model_result?${params.toString()}`;

  const response = await fetch(url, { ...options });
  return returnResponse(response);
};
