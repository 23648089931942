export const KPI_LIST = ['TNR', 'Recall'];
export const PROD_OR_LOT_LIST = ['product_number', 'lot'];
export const AI_INFO_LIST = ['ai_filter_rate', 'fnr', 'fpr'];
export const STATUS_LIST = [
  '---',
  'vrs',
  'ai_rejudged',
  'ai_rejudging',
  'incomplete',
];
export const ALL_ITEM = 'All';
export const CATEGORY_RULE = {
  'OP Rejudge OK': [
    {
      gt: 'P',
      predicted: 'F',
    },
  ],
  OK: [
    {
      gt: 'Uncertain',
      predicted: 'Uncertain',
    },
    {
      gt: 'Uncertain',
      predicted: 'P',
    },
    {
      gt: 'P',
      predicted: 'P',
    },
    {
      gt: 'P',
      predicted: 'Uncertain',
    },
  ],
  NG: [
    {
      gt: 'F',
      predicted: 'F',
    },
    {
      gt: 'Uncertain',
      predicted: 'F',
    },
  ],
  'AI Loss': [
    {
      gt: 'F',
      predicted: 'P',
    },
    {
      gt: 'F',
      predicted: 'Uncertain',
    },
  ],
};

export const TRAINING = ['Initial', 'Queue', 'Started', 'Running'];
export const FAILED = ['Canceled', 'Error', 'Failed'];
export const ONLINE = ['default'];
export const OFFLINE = ['test', 'released'];
export const QUALITY = {
  RELEASED: 'released',
  DEFAULT: 'default',
  TEST: 'test',
};
export const QualityList = [
  {
    name: 'Offline',
    value: QUALITY.RELEASED,
  },
  {
    name: 'Online',
    value: QUALITY.DEFAULT,
  },
  {
    name: 'Offline',
    value: QUALITY.TEST,
  },
];
export const QualityDescription = [
  {
    name: 'Offline',
    description: 'Not Ready for online.',
  },
  {
    name: 'Online',
    description: 'Default use this model if version not be specified.',
  },
];

export const LystroQualityList = [
  {
    name: 'Offline',
    value: QUALITY.TEST,
  },
  {
    name: 'Online',
    value: QUALITY.DEFAULT,
  },
];

export const OPRESULT_MAP = {
  'op-rejudge-ok': 'P',
  'op-rejudge-ng': 'F',
  'op-rejudge-skip': 'SKIP',
  None: null,
};

export const LYSTRO_NGTYPE = [
  'pad_black_foreign',
  'pad_scratch',
  'pad_dent',
  'green_black_foreign',
  'green_gray_discoloration',
  'cu_exposed',
  'base_gray_foreign',
];

export const LYSTRO_CRITERIA = [
  'area_u',
  'area_l',
  'sum_area',
  'area_ng_u',
  'area_ng_l',
];

export const ALERT_MGR_MESSAGE_FROM = 'AlertManager';
