import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';


const Serving_Button_with_Modal = (props) => {
    const {title, modal_title, submit_handler, is_enabled} = props;
    const {t} = useTranslation("serving");
    const [is_show_modal, set_is_show_modal] = useState(false);

    const on_submit = () => {
        submit_handler();
        set_is_show_modal(false);
    }

    return (
        <>
            <Button variant="btn btn-secondary btn--wingray" disabled={!is_enabled}
                    onClick={() => set_is_show_modal(true)}
                    data-testid="button">
                {title}
            </Button>

            <Modal show={is_show_modal}
                   onHide={() => set_is_show_modal(false)}
                   dialogClassName="modal--default modal--sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modal_title ?? title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div data-testid="modal_content">
                        {props.children}
                    </div>

                    <div>
                        <Button type="button" className="btn submit-gray-bule-type btn__outline--dark"
                                style={{float: "right", marginTop: "1em"}}
                                onClick={on_submit}
                                data-testid="modal_button">
                            {t('submit')}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Serving_Button_with_Modal;
