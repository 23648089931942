import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

export const httpCollectSystemLog = async (payload) => {
  /*
        returned response:

        200: Log collection was successful.
        500: Log collection failed
        429: Log collection for this request is limited to only one client at a time.
    */

  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}cambrianServiceProxy/log_collector/collect_log`;

  const response = await fetch(url, { ...options });
  return returnResponse(response, true);
};

export const httpCollectSystemLogStatus = async (payload) => {
  /*
        returned response:
            {
                "is_collecting": True/False,
                "file_path": "/tmp/sosreport-ubuntu-2023-08-07-fkfxaof.tar.xz",
                "file_size": "17.41MiB",
                "start_collection_time": 1691371944,
                "end_collection_time": 1691372106,
                "total_collection_time_seconds": "0 hours, 2 minutes, 42 seconds"
            }
    */

  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}cambrianServiceProxy/log_collector/log_collection_status`;

  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpDownloadSystemLog = async (payload) => {
  const options = getDefaultOptions();
  delete options.headers['Content-Type'];

  const url = `${ServerAPIPrefix}cambrianServiceProxy/log_collector/download_file`;

  const response = await fetch(url, { ...options });
  return returnResponse(response, true);
};
