import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classes from './Serving.module.css'
import Table from "../../UI/Table";
import { get_duration_ago, get_local_time_str } from "../../common";
import { http_get_model_info } from "../../api/model";
import Serving_Button_Model_Status from "./Serving_Button_Model_Status";
import Serving_Button_Model_Lock from "./Serving_Button_Model_Lock";
import Serving_Table_Counter from "./Serving_Table_Counter";
import Button from "react-bootstrap/Button";


const e_model_status = {
    Online: "Online",
    Offline: "Offline",
    Locked: "Locked",
    Unlocked: "Unlocked",
};

const Serving_Table_Model = (props) => {
    const {trigger_counter, reset_counter, update_table_fn} = props;
    const {t} = useTranslation('serving');

    const [model_list_state, set_model_list_state] = useState({is_ready: false, value: []});
    const [show_model_list, set_show_model_list] = useState([]);
    const [is_show_offline, set_is_show_offline] = useState(true);
    const [counter_state, set_counter_state] = useState({
        is_ready: false,
        value: {online: 0, offline: 0, locked: 0}
    });

    function get_model_info() {
        set_model_list_state({is_ready: false, value: model_list_state.value});
        http_get_model_info()
            .then((response) => {
                const count_dict = {online: 0, offline: 0, locked: 0};
                const model_resp_list = response ?? [];
                model_resp_list.push(...[
                    // {
                    //     "token": "demo_project_token",
                    //     "model_name": "demo_model_token_A",
                    //     "model_version": "v0.0.0.19",
                    //     "weight_version": "v0.0.0.19.20230419151212.108844",
                    //
                    //     "triton_loaded": true,
                    //     "protected": true,
                    //
                    //     "hostname": "cloud-serving-0",
                    //     "gpu_memory_consumption": 110,
                    //
                    //     "inference_count": 0,
                    //     "last_inference_timestamp": 1682327619.786922,
                    //     "loaded_timestamp":   1682327317.2822473,
                    //     "deployed_timestamp": 0,
                    // },
                    // {
                    //     "token": "demo_project_token",
                    //     "model_name": "demo_model_token_A",
                    //     "model_version": "v0.0.0.19",
                    //     "weight_version": "v0.0.0.19.20230419151212.108844",
                    //
                    //     "triton_loaded": true,
                    //     "protected": true,
                    //
                    //     "hostname": "cloud-serving-1",
                    //     "gpu_memory_consumption": 320,
                    //
                    //     "inference_count": 0,
                    //     "last_inference_timestamp": 1682327619.786922,
                    //     "loaded_timestamp":   1682327317.2822473,
                    //     "deployed_timestamp": 0,
                    // },
                    // {
                    //     "token": "demo_project_token",
                    //     "model_name": "demo_model_token_A",
                    //     "model_version": "v0.0.0.19",
                    //     "weight_version": "v0.0.0.19.20230419151212.108844",
                    //
                    //     "triton_loaded": false,
                    //     "protected": false,
                    //
                    //     "hostname": "cloud-serving-0",
                    //     "gpu_memory_consumption": null,
                    //
                    //     "inference_count": 0,
                    //     "last_inference_timestamp": null,
                    //     "loaded_timestamp": null,
                    //     "deployed_timestamp": null,
                    // },
                ])
                const model_list = [
                    /*
                    {
                        project_token: "9303209b0014f538c8f88c4239c0752e",
                        model_name: "experience_kit_cnn_learner",
                        model_version: "v0.0.0.19",
                        weight_version: "v0.0.0.19.20230419151212.108844",
                        instance: "cloud-serving-0",

                        is_locked: true,
                        model_status: true,
                        last_inference_ago_sec: 323,
                        created_ago_sec: 324,

                        model_status_list: ['Online'],
                        instance_list: ['instance-0'],
                        mem_used_list: [320],
                        inference_count_list: [1],
                        last_inference_date_list: ['2023-04-25 17:00:00'],
                        last_inference_ago_list: ['2 hours ago'],
                        created_date_list: ['2023-04-25 17:00:00'],
                        created_ago_list: ['2 hours ago'],
                    }
                     */
                ];

                const now = new Date();
                for (const model_resp of model_resp_list) {
                    const year_sec = 365 * 24 * 60 * 60;
                    let last_inference_date = 'Not used',
                        last_inference_ago = t('not_used'),
                        last_inference_ago_sec = model_resp.triton_loaded ? 99 * year_sec : 999 * year_sec,
                        created_date = 'Not created',
                        created_ago = 'Not created',
                        created_ago_sec = model_resp.triton_loaded ? 99 * year_sec : 999 * year_sec;

                    if (model_resp.last_inference_timestamp) {
                        let date = new Date(model_resp.last_inference_timestamp * 1000);
                        last_inference_date = get_local_time_str(date);

                        let ago_dict = get_duration_ago((now - date) / 1000);
                        last_inference_ago = ago_dict.time_value + ' ' + t(ago_dict.time_unit, {ns: "serving"});
                        last_inference_ago_sec = (now - date) / 1000;
                    }

                    if (model_resp.loaded_timestamp) {
                        let date = new Date(model_resp.loaded_timestamp * 1000);
                        created_date = get_local_time_str(date);

                        let ago_dict = get_duration_ago((now - date) / 1000);
                        created_ago = ago_dict.time_value + ' ' + t(ago_dict.time_unit, {ns: "serving"});
                        created_ago_sec = (now - date) / 1000;
                    }

                    const model_status = model_resp.triton_loaded ? e_model_status.Online : e_model_status.Offline;
                    const lock_status = model_resp.protected ? e_model_status.Locked : e_model_status.Unlocked;

                    const model = {
                        model_key: `${model_resp.token}__${model_resp.model_name}__${model_resp.model_version}__${model_resp.weight_version}`,

                        project_token: model_resp.token,
                        model_name: model_resp.model_name,
                        model_version: model_resp.model_version,
                        weight_version: model_resp.weight_version,

                        is_locked: model_resp.protected,
                        online_lock_status: (model_status) + '_' + (lock_status),
                        model_status: model_status,
                        last_inference_ago_sec: last_inference_ago_sec,
                        created_ago_sec: created_ago_sec,

                        model_status_list: [model_resp.triton_loaded ? e_model_status.Online : e_model_status.Offline],
                        instance_list: [model_resp.hostname],
                        mem_used_list: [model_resp.gpu_memory_consumption],
                        inference_count_list: [model_resp.inference_count],
                        last_inference_date_list: [last_inference_date],
                        last_inference_ago_list: [last_inference_ago],
                        created_date_list: [created_date],
                        created_ago_list: [created_ago],
                    }

                    const target_model = model_list.find(item => item.model_key === model.model_key);
                    if (target_model) {
                        if (target_model.model_status === e_model_status.Offline && model.model_status === e_model_status.Online)
                            target_model.model_status = e_model_status.Online;
                        if (target_model.last_inference_ago_sec > model.last_inference_ago_sec)
                            target_model.last_inference_ago_sec = model.last_inference_ago_sec
                        if (target_model.created_ago_sec > model.created_ago_sec)
                            target_model.created_ago_sec = model.created_ago_sec

                        target_model.instance_list.push(model.instance_list[0]);
                        target_model.model_status_list.push(model.model_status_list[0]);
                        target_model.mem_used_list.push(model.mem_used_list[0]);
                        target_model.inference_count_list.push(model.inference_count_list[0]);
                        target_model.last_inference_date_list.push(model.last_inference_date_list[0]);
                        target_model.last_inference_ago_list.push(model.last_inference_ago_list[0]);
                        target_model.created_date_list.push(model.created_date_list[0]);
                        target_model.created_ago_list.push(model.created_ago_list[0]);
                    } else {
                        model_list.push(model)
                    }
                }

                for (const model of model_list) {
                    let ref_sorted_list = [...model.instance_list].sort();
                    let order_list = ref_sorted_list.map((item) => model.instance_list.findIndex(p => p === item))

                    model.instance_list = order_list.map(i => model.instance_list[i])
                    model.model_status_list = order_list.map(i => model.model_status_list[i])
                    model.mem_used_list = order_list.map(i => model.mem_used_list[i])
                    model.inference_count_list = order_list.map(i => model.inference_count_list[i])
                    model.last_inference_date_list = order_list.map(i => model.last_inference_date_list[i])
                    model.last_inference_ago_list = order_list.map(i => model.last_inference_ago_list[i])
                    model.created_date_list = order_list.map(i => model.created_date_list[i])
                    model.created_ago_list = order_list.map(i => model.created_ago_list[i])

                    if (model.model_status === e_model_status.Online)
                        count_dict.online++;
                    else
                        count_dict.offline++;

                    if (model.is_locked)
                        count_dict.locked++;
                }

                set_model_list_state({is_ready: true, value: model_list});
                set_counter_state({is_ready: true, value: count_dict});
            })
            .catch(reason => {
                // toastr.error(reason.message, "Get Model Info");
                set_model_list_state({is_ready: true, value: []});
                set_counter_state({is_ready: true, value: {online: 0, offline: 0}})

                console.error(reason);
            });
    }

    useEffect(() => {
        get_model_info();
    }, [trigger_counter]);

    useEffect(() => {
        set_model_list_state({is_ready: true, value: []});
        set_counter_state({is_ready: true, value: {online: 0, offline: 0}})
    }, [reset_counter]);

    useEffect(() => {
        let model_list = model_list_state.value

        if (!is_show_offline)
            model_list = model_list_state.value.filter(x => x.model_status !== e_model_status.Offline)

        set_show_model_list(model_list)
    }, [model_list_state, is_show_offline])

    function set_model_offline_fn(model_key) {
        const _model_list = structuredClone(model_list_state.value)
        _model_list.filter(model => model.model_key === model_key)
            .map(model => {
                model.model_status = e_model_status.Offline;
                model.model_status_list = model.model_status_list.map(() => e_model_status.Offline);
            });

        set_model_list_state({is_ready: true, value: _model_list})
        update_counter(_model_list);
    }

    function set_model_lock(project_token, model_name, is_locked) {
        const lock_model_list = model_list_state.value.filter(x =>
            x.project_token === project_token && x.model_name === model_name
        );
        lock_model_list.map(model => {
            const lock_status = is_locked ? e_model_status.Locked : e_model_status.Unlocked;

            model.is_locked = is_locked;
            model.online_lock_status = model.status + '_' + lock_status;
        });

        set_model_list_state({is_ready: true, value: model_list_state.value})
        update_counter(model_list_state.value);
    }

    function update_counter(model_list) {
        const count_dict = {online: 0, offline: 0, locked: 0};
        for (const model of model_list) {
            if (model.model_status === e_model_status.Online)
                count_dict.online++;
            else
                count_dict.offline++;

            if (model.is_locked)
                count_dict.locked++;
        }

        set_counter_state({is_ready: true, value: count_dict});
    }

    const column_list = [
        {
            text: t('model', {ns: "serving"}),
            style: {
                maxWidth: '350px',
            },
            dataField: "model_name",
            align: 'left',
            sort: true,
            formatter: (cell, row) => {
                let result = (
                    <div>
                        <span title={'model name'}>{cell}</span>
                        <br />
                        <sup title={'weight version'} style={{fontSize: 'small', color: 'gray'}}>
                            {/*<i>{row.model_version}</i>*/}
                            {/*<b> - </b> */}
                            {row.weight_version}
                        </sup>
                        <br />
                        <span title={'project token'} style={{fontSize: '9px', color: '#56564d'}}># {row.project_token}</span>
                    </div>
                );

                if (row.model_status === e_model_status.Offline)
                    return <span style={{color: "gray"}}>{result}</span>;
                else
                    return result;
            },
        },
        {
            text: t('status', {ns: "serving"}),
            dataField: "model_status",
            align: 'center',
            tdStyle: {verticalAlign: 'middle', backgroundColor: 'red'},
            sort: true,
            formatter: (cell, row) => {
                if (row.model_status === e_model_status.Offline)
                    return <span style={{color: "gray"}}>Offline</span>;
                else
                    return <span>{cell}</span>;
            },
        },
        {
            text: t('instance', {ns: "serving"}),
            dataField: "instance_list",
            align: 'center',
            style: {maxWidth: '180px'},
            sort: false,
            formatter: (cell, row) => {
                return (
                    <span>
                        {cell.map((item, index) => {
                            return row.model_status_list[index] == e_model_status.Online ?
                                <p key={index}>{item}</p>
                                :
                                <p key={index} style={{color: 'gray'}}>{item}</p>
                        })}
                    </span>
                );
            },
        },
        /*
        // hide this for now, since the calculation is wrong
        {
            text: t('used_mem', {ns: "serving"}),
            dataField: "mem_used_list",
            align: 'center',
            headerStyle: {fontSize: '10px', maxWidth: '100px'},
            sort: true,
            formatter: (cell, row) => {
                if (row.model_status === e_model_status.Offline)
                    return null;
                else
                    return (
                        <span>
                            {cell.map((item, index) => {
                                return row.model_status_list[index] == e_model_status.Online ?
                                    <p key={index}>{item}</p>
                                    :
                                    <p key={index} style={{color: 'gray'}}>{e_model_status.Offline}</p>
                            })}
                        </span>
                    );
            },
        },
        */
        {
            text: t('used_count', {ns: "serving"}),
            dataField: "inference_count_list",
            align: 'center',
            headerStyle: {maxWidth: '100px'},
            style: {maxWidth: '100px'},
            sort: true,
            formatter: (cell, row) => {
                if (row.model_status === e_model_status.Offline && cell.reduce((a, b) => a + b) == 0)
                    return null;
                else
                    return (
                        <span>
                            {cell.map((item, index) => {
                                return row.model_status_list[index] == e_model_status.Online ?
                                    <p key={index}>{item}</p>
                                    :
                                    <p key={index} style={{color: 'gray'}}>{item}</p>
                            })}
                        </span>
                    );
            },
        },
        {
            text: t('last_used_time', {ns: "serving"}),
            dataField: "last_inference_ago_sec",
            align: 'center',
            headerStyle: {maxWidth: '150px'},
            style: {maxWidth: '150px'},
            sort: true,
            formatter: (cell, row) => {
                if (row.model_status === e_model_status.Offline)
                    return null;
                else
                    return (
                        <span>
                            {row.last_inference_ago_list.map((item, index) => {
                                return row.model_status_list[index] == e_model_status.Online ?
                                    <p key={index} title={row.last_inference_date_list[index]}>{item}</p>
                                    :
                                    <p key={index} style={{color: 'gray'}}>{e_model_status.Offline}</p>
                            })}
                        </span>
                    );
            },
        },

        {
            text: t('created_time', {ns: "serving"}),
            dataField: "created_ago_sec",
            align: 'center',
            sort: true,
            formatter: (cell, row) => {
                if (row.model_status === e_model_status.Offline)
                    return null;
                else
                    return (
                        <span>
                            {row.created_ago_list.map((item, index) => {
                                return row.model_status_list[index] == e_model_status.Online ?
                                    <p key={index} title={row.created_date_list[index]}>{item}</p>
                                    :
                                    <p key={index} style={{color: 'gray'}}>{e_model_status.Offline}</p>
                            })}
                        </span>
                    );
            },
        },
        {
            text: t('lock_status', {ns: "serving"}),
            dataField: "is_locked",
            hidden: true,
            align: 'center',
            sort: true,
            formatter: (cell, row) => {
                const locked_model_list = model_list_state.value.filter((obj) =>
                    obj.model_name === row.model_name && obj.project_token === row.project_token
                );
                const model_weight_list = locked_model_list.map((obj) => obj.weight_version);

                return <>
                    <Serving_Button_Model_Lock is_locked={cell}
                                               model_key={row.model_key}
                                               model_weight_count={model_weight_list.length}
                                               set_model_lock_fn={set_model_lock}
                                               update_table_fn={update_table_fn}
                                               project_token={row.project_token}
                                               model_name={row.model_name} />
                </>;
            },
        },
        {
            text: t('action', {ns: "serving"}),
            dataField: "online_lock_status",
            align: 'center',
            sort: true,
            formatter: (cell, row) => {
                return <Serving_Button_Model_Status is_online={row.model_status == e_model_status.Online}
                                                    is_locked={row.is_locked}
                                                    model_key={row.model_key}
                                                    set_model_offline_fn={set_model_offline_fn}
                                                    update_table_fn={update_table_fn}
                                                    project_token={row.project_token}
                                                    model_name={row.model_name}
                                                    model_version={row.model_version}
                                                    weight_version={row.weight_version} />;
            },
        },
        {
            dataField: "model_key",
            hidden: true,
        },
    ];

    const tableController = () => {
        return(
            <button
                className={`btn btn-md btn__outline--light ${classes['btn--control']}`}
            >
                {t('button.add')}
            </button>
        )
    }

    return (
        <Fragment>
            <div style={{color: 'gray', float: 'right'}}>
                <Button variant="btn btn-secondary btn--wingray" disabled={!model_list_state.is_ready}
                        onClick={() => set_is_show_offline(!is_show_offline)}
                        data-testid="refresh_button">
                    {is_show_offline ? t('hide_offline_model') : t('show_offline_model')}
                </Button>
                <span> </span>
                <Button variant="btn btn-secondary btn--wingray" disabled={!model_list_state.is_ready}
                        onClick={get_model_info}
                        data-testid="refresh_button">
                    {model_list_state.is_ready ?
                        <FontAwesomeIcon icon={solid('rotate-right')} size="1x" />
                        :
                        <FontAwesomeIcon icon={solid('spinner')} size="1x" spin />
                    }
                </Button>
            </div>

            <Serving_Table_Counter counter_state={counter_state} offline_color={''} />
            <div style={{backgroundColor: '#0e243e'}}>
                <Table
                    keyField="model_key"
                    columns={column_list}
                    data={show_model_list}
                    defaultSorted={[{dataField: 'last_inference_ago_sec', order: 'asc'}]}
                    tableClass={classes["tb-license"]}
                    controllerWrapperClass={classes["table-controller"]}
                    searchWrapperClass={classes["table-search"]}
                    paginationOption={undefined}
                    tableWrapperClass={classes["tb-license--wrapper"]}
                    // controller={tableController}
                />
            </div>
        </Fragment>
    )
}

export default Serving_Table_Model
