import React from 'react';
import Serving from '../../../backend/serving/Serving';
import { Tab, Tabs } from 'react-bootstrap';
import AlertManager from './AlertManager/AlertManager';
import styles from './monitor.module.css';
import { useTranslation } from 'react-i18next';

const Monitor = () => {
  const { t } = useTranslation();

  return (
    <Tabs className={styles.tabs}>
      <Tab eventKey='system' title={t('common.system', { ns: 'translation' })}>
        <Serving theme='dark' />
      </Tab>
      <Tab eventKey='alerts' title={t('alerts', { ns: 'smt_rejudge' })}>
        <AlertManager />
      </Tab>
    </Tabs>
  );
};

export default Monitor;
