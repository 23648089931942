import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

export const httpGetDeviceInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}cambrianServiceProxy/dev_mgr/hw_info`;

  const response = await fetch(url, { ...options });
  return returnResponse(response);
};
