import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { http_model_lock, http_model_unload } from "../../api/model";
import Serving_Button_with_Modal from "./Serving_Button_with_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";


const Serving_Button_Model_Lock = (props) => {
    const {is_locked, model_weight_count, set_model_lock_fn, update_table_fn,
        project_token, model_name} = props;
    const {t} = useTranslation("serving");

    const [is_ready, set_is_ready] = useState(true);

    const submit_handler = () => {
        set_is_ready(false);
        http_model_lock(project_token, model_name, !is_locked)
            .then((response) => {
                set_is_ready(true);
                set_model_lock_fn(project_token, model_name, !is_locked);
                update_table_fn();

                toastr.success("Success");
            })
            .catch(reason => {
                set_is_ready(true);
                toastr.error(reason.message, "Unload Model");
                console.error(reason);
            });
    }

    let Icon = <FontAwesomeIcon icon={solid('lock-open')} size="1x" />
    if (is_locked)
        Icon = <FontAwesomeIcon icon={solid('lock')} size="1x" />

    if (!is_ready)
        Icon = <FontAwesomeIcon style={{color: 'white', marginLeft: '5px'}} size="1x" icon={solid('spinner')} spin />;

    return (<>
        <Serving_Button_with_Modal title={Icon}
                                   modal_title={t('lock_status')}
                                   submit_handler={submit_handler}
                                   is_enabled={is_ready}>
            {t('confirm')}
            <span style={{textTransform: 'lowercase'}}>&nbsp;
                {is_locked ? t('unlock_model_online') : t('lock_model_online')}
            </span>..?
            <br />
            <span style={{fontSize: 'small'}}>({t('model')} * {model_weight_count})</span>
        </Serving_Button_with_Modal>

        {/*{!is_ready &&*/}
        {/*    <FontAwesomeIcon style={{color: 'white', marginLeft: '5px'}} size="1x" icon={solid('spinner')} spin />*/}
        {/*}*/}
    </>)
}

export default Serving_Button_Model_Lock;
