import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';


const Serving_Table_Counter = (props) => {
    const {counter_state, offline_color} = props;
    const {t} = useTranslation('serving');
    const { pathname } = useLocation();

    return (
        <Fragment>
            <table>
                <tbody style={{paddingRight: '5px', fontSize: pathname?.includes("smtrejudge") && '1rem', color: '#687088', fontWeight: 'bolder'}}>
                <tr>
                    <td>
                        <label>
                            Online
                        </label>
                    </td>
                    <td>
                        :&nbsp;
                        <span data-testid="online_value">
                            {counter_state.value.online}
                        </span>
                    </td>
                    {false &&
                    <td style={{paddingLeft: '15px'}}>
                        {0 < counter_state.value.locked &&
                            <span style={{fontSize: 'smaller', color: 'gray'}}>
                                ({t('lock')}: {counter_state.value.locked})
                            </span>
                        }
                    </td>
                    }
                </tr>
                <tr>
                    <td >
                        <label style={{ color: counter_state.value.offline ? offline_color : ''}}>
                            Offline
                        </label>
                    </td>
                    <td>
                        :&nbsp;
                        <span style={{color: counter_state.value.offline ? offline_color : ''}}
                              data-testid="offline_value">
                            {counter_state.value.offline}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </Fragment>
    )
}

export default Serving_Table_Counter
