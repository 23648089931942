import React, { useEffect, useState } from 'react'
import { Collapse } from "react-bootstrap";
import { useLocation } from 'react-router';

const Card = (props) => {
    const {is_collapse: is_collapse_default, theme} = props;
    let default_collapse = is_collapse_default != undefined ? is_collapse_default : true;

    const [is_collapse, set_is_collapse] = useState(default_collapse);
    const collapseIcon = is_collapse ? <i className="pegafont icon-right-open-1"></i> : <i className="pegafont icon-down-open-1"></i>;
    const { pathname } = useLocation();

    const themeStyle = pathname?.includes("smtrejudge") ? {
        dark:
            {
                background: "#0b1c31",
                borderRadius: 0,
                fontSize: '1rem'
            }
    } : {
      dark:
            {
                background: "#0b1c31",
                borderRadius: 0,
            }
    }

    const selectedTheme = theme && themeStyle[theme] ? themeStyle[theme] : {}

    return (
        <React.Fragment>
            <div className="card info__card" style={{ marginTop: "1em" }}>
                <span
                    className="card-header info__card__header--black"
                    style={{ cursor: "pointer", fontSize: pathname?.includes("smtrejudge") && '1.1rem' }}
                    onClick={() => set_is_collapse(!is_collapse)}
                    aria-controls="collapse-general-settings"
                    aria-expanded={!is_collapse}
                >
                    {collapseIcon}&nbsp;{props.title}
                </span>
                <Collapse in={!is_collapse}>
                    <div id="collapse-general-settings" className="info__card__body"
                        style={selectedTheme}
                    >
                        {props.children}
                    </div>
                </Collapse>
            </div>
        </React.Fragment>
    )
}

export default Card
