import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Serving_Table_Instance from './Serving_Table_Instance';
import Serving_Table_Model from './Serving_Table_Model';
import Serving_Button_Sevice_Restart from './Serving_Button_Sevice_Restart';
import Card from '../../group/subscription/group/card';
import EdgeDeviceInfo from '../DeviceInfo';
import { checkIsFunctionEnabled } from '../../utils/common_utils';

const Serving = (props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation('serving');
  const { theme } = props;
  const [trigger_counter, set_trigger_counter] = useState(0);
  const [reset_counter, set_reset_counter] = useState(0);

  function update_table_fn() {
    set_trigger_counter(trigger_counter + 1);
  }

  function reset_table_fn() {
    set_reset_counter(reset_counter + 1);
  }

  return (
    <div>
      <Card title={t('instance')} is_collapse={false} theme={theme}>
        <Serving_Table_Instance
          trigger_counter={trigger_counter}
          reset_counter={reset_counter}
          update_table_fn={update_table_fn}
        />
        {!pathname.includes('smtrejudge') && (
          <div style={{ marginTop: '10px' }}>
            <Serving_Button_Sevice_Restart
              update_table_fn={update_table_fn}
              reset_table_fn={reset_table_fn}
            />
          </div>
        )}
      </Card>
      <Card title={t('model')} is_collapse={false} theme={theme}>
        <Serving_Table_Model
          trigger_counter={trigger_counter}
          reset_counter={reset_counter}
          update_table_fn={update_table_fn}
        />
      </Card>
      {checkIsFunctionEnabled('ENABLE_BACKEND_DEVICE') && (
        <Card title='Device Info' is_collapse={false} theme={theme}>
          <EdgeDeviceInfo />
        </Card>
      )}
    </div>
  );
};

export default Serving;
