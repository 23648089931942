exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39WmwtmTYT-LwnR8B7vOij {\n  margin-top: 0 !important;\n  min-width: 700px !important;\n  margin-bottom: 0 !important;\n}\n\n._1u93msGs7Am80yiCaPPGqf .react-bootstrap-table {\n  overflow: auto;\n  margin-bottom: 0;\n}\n\n._b-e0gQrbmf1DRpvkb9zb {\n  background-color: #242737;\n  color: #d1d3e1;\n  height: 50px;\n  align-items: center !important;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n\n._3iYYFsU1wvQGAf80g7D7Cp {\n  align-self: center !important;\n}\n\n._39WmwtmTYT-LwnR8B7vOij a {\n  text-decoration: underline !important;\n}\n\n._3xa_hxZ0a8HcUPb8Ayn_23 {\n  color: rgb(209, 211, 225);\n  font-family: Roboto;\n}\n\n._2ZznE9Pbt00-fftdlAc-9d {\n  display: unset;\n  padding: 0;\n}\n\n._22MiMyc2dYVmEIxWWW2tpo {\n  width: 100%;\n  border: 1px solid #808694 !important;\n  font-size: 85%;\n  border-radius: 2px;\n  color: #464a5b;\n}\n\n._22MiMyc2dYVmEIxWWW2tpo:focus {\n  border: 1px solid #808694 !important;\n  outline: none !important;\n}\n\n._10GZUtQKYooGhQSwWXHPVd {\n  color: red;\n  min-height: 25px;\n}\n\n.Brem0AO1g4xeq2UxaD8UQ {\n  color: white;\n}\n\n._26xWRN3NzK2SIhxNWLf13l {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n._26xWRN3NzK2SIhxNWLf13l iframe {\n  width: 100%;\n  height: 850px;\n  border: 0;\n}\n", ""]);

// exports
exports.locals = {
	"tb-license": "_39WmwtmTYT-LwnR8B7vOij",
	"tb-license--wrapper": "_1u93msGs7Am80yiCaPPGqf",
	"table-controller": "_b-e0gQrbmf1DRpvkb9zb",
	"table-search": "_3iYYFsU1wvQGAf80g7D7Cp",
	"header-title": "_3xa_hxZ0a8HcUPb8Ayn_23",
	"link-activation": "_2ZznE9Pbt00-fftdlAc-9d",
	"input-offline-activation": "_22MiMyc2dYVmEIxWWW2tpo",
	"error-message": "_10GZUtQKYooGhQSwWXHPVd",
	"menu-actions": "Brem0AO1g4xeq2UxaD8UQ",
	"modal-body-container": "_26xWRN3NzK2SIhxNWLf13l"
};