exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2gbuC_mvSdhc3DJ8eu7FHm .nav-link.active {\n  background-color: #023c58 !important;\n  border-color: white !important;\n  color: white !important;\n}\n\n._2gbuC_mvSdhc3DJ8eu7FHm .nav-link {\n  color: #6ea8fe;\n}\n", ""]);

// exports
exports.locals = {
	"tabs": "_2gbuC_mvSdhc3DJ8eu7FHm"
};