import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Serving_Button_with_Modal from "./Serving_Button_with_Modal";
import { http_set_model_status } from "../../api/model";
import toastr from 'toastr';


const Serving_Button_Model_Status = (props) => {
    const {is_online, is_locked, model_key, set_model_offline_fn, update_table_fn,
        project_token, model_name, model_version, weight_version} = props;
    const {t} = useTranslation("serving");

    const [is_ready, set_is_ready] = useState(true);

    const submit_handler = () => {
        set_is_ready(false);
        http_set_model_status({
            'Service-Token': project_token,
            'Service-Name': model_name,
            'Service-Version': model_version,
            'Service-Weight': weight_version,
        }, !is_online)
            .then((response) => {
                set_is_ready(true);
                update_table_fn();
                if (is_online)
                    set_model_offline_fn(model_key);

                toastr.success("Success");
            })
            .catch(reason => {
                set_is_ready(true);
                toastr.error(reason.message, is_online ? "Unload Model" : "Load Model");
                console.error(reason);
            });
    }

    let title = t('load_model');
    if (is_online)
        title = t('unload_model');
    if (!is_ready)
        title = <FontAwesomeIcon style={{color: 'white', marginLeft: '5px'}} size="1x" icon={solid('spinner')} spin />;

    return (<>
        <Serving_Button_with_Modal
            title={title}
            submit_handler={submit_handler}
            is_enabled={!is_locked && is_ready}
        >
            {t('confirm')} <span style={{textTransform: 'lowercase'}}> {title}</span>..?
        </Serving_Button_with_Modal>
    </>)
}

export default Serving_Button_Model_Status;
