import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './Serving.module.css'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../UI/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { get_duration_ago, get_local_time_str } from "../../common";
import { http_get_instance_info } from "../../api/model";
import Serving_Table_Counter from "./Serving_Table_Counter";
import { checkIsFunctionEnabled } from '../../utils/common_utils';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';

const Serving_Table_Instance = (props) => {
  const { trigger_counter, reset_counter, update_table_fn } = props;
  const intervalTime = window.INSTANCE_INTERVAL ? parseInt(window.INSTANCE_INTERVAL): 5000;
  // console.log('intervalTime:::',intervalTime);
  const [is_show_modal, set_is_show_modal] = useState(false);
  const { t } = useTranslation();
  const deviceEdgeInfo = useSelector(state => state.deviceInfo.deviceEdgeInfo)

  const [instance_list_state, set_instance_list_state] = useState({ is_ready: false, value: [] });
  const [counter_state, set_counter_state] = useState({
    is_ready: false,
    value: { online: 0, offline: 0 }
  });

  const grafana_monitoring_service = window.location.origin.match(/^(.*:\/\/[^:]+)(?::|$)/)[1] + ":30001";
  const SERVING_DASHBOARD_URL = grafana_monitoring_service + '/d/Model-mgr-uid/nginx-ingress-controller-monitor-service-model-mgr?orgId=1&kiosk&refresh=5s'

  function get_instance_info() {
    // set_instance_list_state({is_ready: false, value: instance_list_state.value});
    http_get_instance_info()
      .then((response) => {
        const count_dict = { online: 0, offline: 0 };
        const instance_list = [];
        let instance_dict = {
          ...response,
          // "cloud-serving-demo-A": {
          //     "status": "online",
          //     "total": 3638.1375,
          //     "used": 105.125,
          //     "inference_count": 2,
          //     "last_inference_time": 1682327619,
          //     "create_time": 1682304594,
          // },
          // "cloud-serving-demo-B": {
          //     "status": "offline"
          // }
        }

        const now = new Date();
        for (const key in instance_dict) {
          let last_inference_date = 'Not used',
            last_inference_ago = 'Not used',
            created_date = 'Not created',
            created_ago = 'created date info lost';

          if (instance_dict[key].last_inference_time) {
            let date = new Date(instance_dict[key].last_inference_time * 1000);
            last_inference_date = get_local_time_str(date);
            let ago_dict = get_duration_ago((now - date) / 1000);
            last_inference_ago = ago_dict.time_value + ' ' + t(ago_dict.time_unit, { ns: "serving" });
          }

          if (instance_dict[key].create_time) {
            let date = new Date(instance_dict[key].create_time * 1000);
            created_date = get_local_time_str(date);

            let ago_dict = get_duration_ago((now - date) / 1000);
            created_ago = ago_dict.time_value + ' ' + t(ago_dict.time_unit, { ns: "serving" });
          }

          if (instance_dict[key].status == 'online')
            count_dict.online++;
          else
            count_dict.offline++;

          instance_list.push({
            instance_name: key,
            is_online: instance_dict[key].status == 'online',
            status: instance_dict[key].status,
            mem_total: instance_dict[key].total,
            mem_used: instance_dict[key].used,
            inference_count: instance_dict[key].inference_count,
            last_inference_time: instance_dict[key].last_inference_time,
            last_inference_date: last_inference_date,
            last_inference_ago: last_inference_ago,
            created_date: created_date,
            created_ago: created_ago,
          });
        }

        set_instance_list_state({ is_ready: true, value: instance_list });
        set_counter_state({ is_ready: true, value: count_dict })

        if (counter_state.value.online != count_dict.online)
          update_table_fn();
      })
      .catch(reason => {
        set_instance_list_state({ is_ready: true, value: [] });
        set_counter_state({ is_ready: true, value: { online: 0, offline: 0 } })

        update_table_fn();

        //toastr.error(reason.message, "Get Instance Info");
        // toastr.error(t('get_instance_failed', { ns: "serving" }));
        // console.error(reason);
      });
  }

  useEffect(() => {
    get_instance_info();
  }, [trigger_counter]);

  useEffect(() => {
    set_instance_list_state({ is_ready: true, value: [] });
    set_counter_state({ is_ready: true, value: { online: 0, offline: 0 } })
  }, [reset_counter]);

  useEffect(() => {
    let poll_timeout_id = 0;

    function setup_polling_timout() {
      poll_timeout_id = setTimeout(async () => {
        await get_instance_info();
        setup_polling_timout();
      }, intervalTime);
    }

    setup_polling_timout();

    return () => {
      clearTimeout(poll_timeout_id)
    };
  }, []);

  const showServingDashboard = (row) => {
    set_is_show_modal(true)
  }

  const column_list = [
    {
      text: t('instance', { ns: "serving" }),
      style: {
        maxWidth: '400px',
      },
      dataField: "instance_name",
      align: 'center',
      sort: true,
      formatter: (cell, row) => {
        return (
          <React.Fragment>

            {deviceEdgeInfo && deviceEdgeInfo.GPU_driver_ver && deviceEdgeInfo.GPUs && deviceEdgeInfo.GPUs.length > 0 &&
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div style={{ textAlign: "left" }}>
                      <label>Model: </label><span>{deviceEdgeInfo.GPUs[0]["UUID"]}</span>
                      <br />
                      <label>Driver: </label><span>{deviceEdgeInfo.GPU_driver_ver}</span>
                    </div>
                  </Tooltip>
                }
              >
                <span style={{ marginRight: "5px" }}><FontAwesomeIcon icon={solid("info-circle")} /></span>
              </OverlayTrigger>
            }

            {!row.is_online &&
              <span style={{ color: "red" }}>{cell}</span>
            }
            {row.is_online &&

              <span>{cell}</span>
            }
          </React.Fragment>
        )
      },
      formatExtraData: () => {
        return deviceEdgeInfo
      },
    },
    {
      text: t('status', { ns: "serving" }),
      dataField: "status",
      align: 'center',
      tdStyle: { verticalAlign: 'middle', backgroundColor: 'red' },
      sort: true,
      formatter: (cell, row) => {
        if (!row.is_online)
          return <span style={{ color: "red" }}>Offline</span>;
        else
          return <span>Online</span>;
      },
    },
    {
      text: t('used_total_mem', { ns: "serving" }),
      dataField: "mem_used",
      align: 'center',
      sort: false,
      formatter: (cell, row) => {
        if (!row.is_online)
          return null;
        else {
          let used_str = Math.round(row.mem_used).toLocaleString('en-US');
          let total_str = Math.round(row.mem_total).toLocaleString('en-US');
          let percent = Math.floor(row.mem_used / row.mem_total * 100);

          let used_progress_str = '';
          let free_progress_str = '';
          if (percent > 50)
            used_progress_str = used_str + ' / ' + total_str;
          else
            free_progress_str = used_str + ' / ' + total_str

          return (<div className="progress"
            style={{ color: 'black', textAlign: 'center', marginTop: '1px', marginBottom: '20px' }}>
            <div className="progress-bar progress-bar-striped"
              style={{ width: percent + "%", backgroundColor: 'darkcyan' }}>
              {used_progress_str}
            </div>
            <div style={{ width: 100 - percent + "%" }}>
              {free_progress_str}
            </div>
          </div>);
        }
      },
    },
    {
      text: t('used_count', { ns: "serving" }),
      dataField: "inference_count",
      align: 'right',
      sort: true,
      formatter: (cell, row) => {
        if (!row.is_online)
          return null;
        else if (cell == 0)
          return <span style={{ color: "gray" }}>{cell}</span>;
        else
          return <span>{cell}</span>;
      },
    },
    {
      text: t('last_used_time', { ns: "serving" }),
      dataField: "last_inference_ago",
      align: 'right',
      sort: true,
      formatter: (cell, row) => {
        if (!row.is_online)
          return null;
        else if (row.inference_count == 0)
          return <span style={{ color: "gray" }}>{t('not_used', { ns: "serving" })}</span>;
        else
          return <span title={row.last_inference_date}>{cell}</span>;
      },
    },
    {
      text: t('created_time', { ns: "serving" }),
      dataField: "created_ago",
      align: 'right',
      sort: true,
      formatter: (cell, row) => {
        if (!row.is_online)
          return null;
        else
          return <span title={row.created_date}>{cell}</span>;
      },
    },
    {
      dataField: "last_inference_date",
      hidden: true,
    },
    {
      dataField: "created_date",
      hidden: true,
    }
  ];

  if (checkIsFunctionEnabled("ENABLE_MODEL_SERVING_MONITORING")) {
    column_list.push({
      dataField: "actions",
      align: 'center',
      text: 'Actions',
      formatter: (cell, row) => {
        if (!row.is_online) {
          return null;
        } else {
          return (
            <button
              className={`btn btn-md btn__outline--light ${classes['btn--control']}`}
              onClick={() => showServingDashboard(row)}
            >
              <FontAwesomeIcon icon={faChartLine} />
            </button>
          );
        }
      },
    });
  }

  const tableController = () => {
    return (
      <button
        className={`btn btn-md btn__outline--light ${classes['btn--control']}`}
      >
        {t('button.add')}
      </button>
    )
  }

  return (
    <Fragment>
      {/*<div style={{visibility: instance_list_state.is_ready ? 'hidden' : 'visible', xcolor: 'gray', border: 'none'}}>*/}
      {/*    <FontAwesomeIcon icon={solid('spinner')} size="1x" spin />*/}
      {/*</div>*/}
      <div>
        <Serving_Table_Counter counter_state={counter_state} offline_color={'red'} />
        <Table
          keyField="instance_name"
          columns={column_list}
          data={instance_list_state.value}
          defaultSorted={[{ dataField: 'instance_name', order: 'asc' }]}
          tableClass={classes["tb-license"]}
          controllerWrapperClass={classes["table-controller"]}
          searchWrapperClass={classes["table-search"]}
          paginationOption={null}
          tableWrapperClass={classes["tb-license--wrapper"]}
        // controller={tableController}
        />
      </div>
      <Modal show={is_show_modal}
        onHide={() => set_is_show_modal(false)}
        dialogClassName="modal--default modal--full"
      >
        <Modal.Header closeButton>
          <Modal.Title>Model Manager Serving Metrics Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes["modal-body-container"]}>
            <iframe src={SERVING_DASHBOARD_URL} frameBorder="0"></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default Serving_Table_Instance
