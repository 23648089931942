import React from 'react';
import {
  Route,
  Navigate,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom';
import {
  checkIsAdmin,
  checkIsGuest,
  getCookie,
  setAuthCookie,
  checkSSOAuthActive,
  lazyRetry,
  SUBSCRIPTION_ENTRY_TYPE,
} from './common';

import Layout from './layout';
import { LDAPLogout } from './login';
import PromotionalVideos from './PromotionalVideos/PromotionalVideos';
import Monitor from './SMTrejudge/Setting/Monitor';

const LDAPLogin = React.lazy(() =>
  lazyRetry(() =>
    import('./login').then((module) => ({ default: module.LDAPLogin })),
  ),
);
const LDAPSignUp = React.lazy(() =>
  lazyRetry(() =>
    import('./SignUp').then((module) => ({ default: module.LDAPSignUp })),
  ),
);
const MainMenu = React.lazy(() => lazyRetry(() => import('./home')));
const Deploy = React.lazy(() => lazyRetry(() => import('./deploy/deploy')));
const AdminDashboard = React.lazy(() =>
  lazyRetry(() =>
    import('./backend/admin').then((module) => ({
      default: module.AdminDashboard,
    })),
  ),
);
const UserUsageDownload = React.lazy(() =>
  lazyRetry(() =>
    import('./backend/admin').then((module) => ({
      default: module.UserUsageDownloadWithTranslation,
    })),
  ),
);
const Users = React.lazy(() =>
  lazyRetry(() =>
    import('./backend/users').then((module) => ({
      default: module.UsersWithTranslation,
    })),
  ),
);
const Auth = React.lazy(() => lazyRetry(() => import('./backend/auth')));
const Email = React.lazy(() => lazyRetry(() => import('./backend/mail')));
const BackendProjects = React.lazy(() =>
  lazyRetry(() => import('./backend/BackendProjects')),
);
const Package = React.lazy(() =>
  lazyRetry(() => import('./backend/package/Package')),
);
const Serving = React.lazy(() =>
  lazyRetry(() => import('./backend/serving/Serving')),
);

const AIAssistant = React.lazy(() =>
  lazyRetry(() => import('./backend/AIAssistant')),
);

const UsageStats = React.lazy(() =>
  lazyRetry(() => import('./backend/UsageStats')),
);

const ContactUs = React.lazy(() =>
  lazyRetry(() => import('./backend/ContactUs')),
);

const FeedbackManagement = React.lazy(() =>
  lazyRetry(() => import('./backend/FeedbackManagement')),
);
const DeviceInfo = React.lazy(() =>
  lazyRetry(() => import('./backend/DeviceInfo.jsx')),
);

const Solutions = React.lazy(() =>
  lazyRetry(() =>
    import('./Solution_v2/SolutionsPage').then((module) => ({
      default: module.Solutions,
    })),
  ),
);
const SolutionManagement = React.lazy(() =>
  lazyRetry(() => import('./Solution/SolutionManagement')),
);
const SolutionTemplate = React.lazy(() =>
  lazyRetry(() => import('./Solution/Solutions_template')),
);
const SolutionFakeTemplate = React.lazy(() =>
  lazyRetry(() => import('./Solution/solutions_template_fake')),
);
const SolutionsSetup = React.lazy(() =>
  lazyRetry(() =>
    import('./Solution/solutions_setup').then((module) => ({
      default: module.SolutionsSetup,
    })),
  ),
);
const SolutionsSettings = React.lazy(() =>
  lazyRetry(() =>
    import('./Solution/solutions_settings').then((module) => ({
      default: module.SolutionsSettings,
    })),
  ),
);

const SolutionItem = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionItem')),
);
const SolutionsModels = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionModelList')),
);
const SolutionModelItem = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionModelItem')),
);
const SolutionIconMenu = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionIconMenu')),
);
const SolutionGuide = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionGuide')),
);
const SolutionResourceUsage = React.lazy(() =>
  lazyRetry(() => import('./Solution_v2/SolutionResourceUsage')),
);
const SolutionsPerformance = React.lazy(() =>
  lazyRetry(() => import('./Solution/solutions_performance')),
);

const Projects = React.lazy(() =>
  lazyRetry(() =>
    import('./workspace/projects').then((module) => ({
      default: module.ProjectsWithRouter,
    })),
  ),
);
const ProjectsItem = React.lazy(() =>
  lazyRetry(() =>
    import('./workspace/project_item').then((module) => ({
      default: module.ProjectsItemwithTranslation,
    })),
  ),
);
const Datasets = React.lazy(() =>
  lazyRetry(() =>
    import('./workspace/datasets').then((module) => ({
      default: module.DatasetsWithRouter,
    })),
  ),
);
const DatasetBody = React.lazy(() =>
  lazyRetry(() =>
    import('./workspace/dataset_body').then((module) => ({
      default: module.DatasetBodywithTranslation,
    })),
  ),
);
const GrafanaDashboard = React.lazy(() =>
  lazyRetry(() =>
    import('./grafana_dashboard').then((module) => ({
      default: module.GrafanaDashboardWithRouter,
    })),
  ),
);
const MergeDataset = React.lazy(() =>
  lazyRetry(() => import('./Datasets/MergeDataset')),
);
const SMTrejudgeLayout = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge')),
);
const SMTrejudgeCreate = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/Create')),
);
const SMTrejudgeStations = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/Stations')),
);
const SMTDetailUI = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/SMTDetail')),
);
const SMTSetting = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/Setting')),
);
const SMTJob = React.lazy(() => lazyRetry(() => import('./SMTrejudge/Job')));
const SMTOverall = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/Overall')),
);
const Train = React.lazy(() => lazyRetry(() => import('./SMTrejudge/Train')));
const Model = React.lazy(() =>
  lazyRetry(() => import('./SMTrejudge/Train/Model')),
);

const SubscriptionPage = React.lazy(() =>
  lazyRetry(() => import('./group/SubsriptionPage')),
);
const Subscription = React.lazy(() =>
  lazyRetry(() => import('./group/subscription/subscription')),
);
const Storage_Quota = React.lazy(() =>
  lazyRetry(() => import('./group/subscription/group/group_project_quota')),
);
const Billing = React.lazy(() =>
  lazyRetry(() => import('./group/subscription/Billing/Billing')),
);
const ListLicense = React.lazy(() =>
  lazyRetry(() => import('./License/ListLicense')),
);
const ListActivation = React.lazy(() =>
  lazyRetry(() => import('./License/ListActivation')),
);
const User_Settings_Page = React.lazy(() =>
  lazyRetry(() => import('./User/User_Settings_Page')),
);
const EdgeRuntime = React.lazy(() =>
  lazyRetry(() => import('./EdgeRuntime/EdgeRuntime.jsx')),
);
const PlaygroundList = React.lazy(() =>
  lazyRetry(() => import('./Playground/PlaygroundList')),
);
const Application = React.lazy(() =>
  lazyRetry(() => import('./Playground/Application/Application')),
);
const DigitalAssistantRoute = React.lazy(() =>
  lazyRetry(() => import('./DigitalAssistant/DigitalAssistantRoute.jsx')),
);
const ListApplication = React.lazy(() =>
  lazyRetry(() => import('./Playground/Application/ListApplication')),
);

const JobResult = React.lazy(() =>
  lazyRetry(() => import('./Iplas/Jobs/Result')),
);

// waits for the Go-compiled config from the window object
const waitAndGetRouteConfig = (config) => {
  return new Promise((resolve) => {
    const checkConfig = () => {
      if (window[config] !== undefined) {
        resolve(window[config]);
      } else {
        setTimeout(checkConfig, 100);
      }
    };
    checkConfig();
  });
};

const createLoaderWithRedirect = () => {
  return async () => {
    const ENABLE_AI_ASSISTANT_STANDALONE = await waitAndGetRouteConfig(
      'ENABLE_AI_ASSISTANT_STANDALONE',
    );
    const ENABLE_GROUP = await waitAndGetRouteConfig('ENABLE_GROUP');

    if (ENABLE_AI_ASSISTANT_STANDALONE === 'true') {
      return redirect('/digitalassistant/ai_assistant');
    }
    if (ENABLE_GROUP === 'true') return redirect('/');

    return null;
  };
};

const createLoaderForDefaultRoute = () => {
  return async ({ request }) => {
    const currentUrl = new URL(request.url);

    const ENABLE_AI_ASSISTANT_STANDALONE = await waitAndGetRouteConfig(
      'ENABLE_AI_ASSISTANT_STANDALONE',
    );

    if (
      ENABLE_AI_ASSISTANT_STANDALONE === 'true' &&
      currentUrl.pathname === '/backend'
    ) {
      return redirect('/backend/users');
    }

    return null;
  };
};

function RequireAuth({ admin = false, guest = false, element }) {
  const location = useLocation();
  const authData = getCookie('AuthData');
  const isSSOAuth = checkSSOAuthActive();
  const refreshToken = getCookie('refresh_token');

  if (!refreshToken) {
    return <Navigate to='/login' replace />;
  }

  if (!authData && isSSOAuth) {
    const { search, pathname } = location;
    setAuthCookie(search, pathname);
  } else if (!authData && !isSSOAuth) {
    return <Navigate to='/login' replace />;
  }

  if (admin && !checkIsAdmin()) {
    const homepageUrl = window.HOMEPAGE || '/';
    return <Navigate to={homepageUrl} replace />;
  }

  if (!guest && checkIsGuest()) {
    return <Navigate to='/playground' replace />;
  }

  return element;
}

const PageRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/' element={<Layout />}>
        <Route index element={<RequireAuth element={<MainMenu />} />} />

        <Route path='edge/*' element={<EdgeRuntime />} />

        <Route path='solutions'>
          <Route index element={<Navigate to='list/' replace />} />
          <Route path='list'>
            <Route index element={<Navigate to='shop' replace />} />
            <Route
              path='my'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
            <Route
              path='all'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
            <Route
              index
              path='shop'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
            <Route
              path='archive'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
          </Route>
          <Route
            path='fake'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SolutionFakeTemplate />} />}
          />
          <Route
            path='management'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SolutionManagement />} />}
          />
          <Route
            path=':token'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SolutionTemplate />} />}
          />
          <Route
            path=':token/:version'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SolutionItem />} />}
          >
            <Route index element={<Navigate to='solution' replace />} />
            <Route path='solution'>
              <Route
                index
                element={<RequireAuth element={<SolutionIconMenu />} />}
              />
              <Route
                path='setup'
                element={<RequireAuth element={<SolutionsSetup />} />}
              />
              <Route path='performance'>
                <Route
                  index
                  element={<RequireAuth element={<SolutionsPerformance />} />}
                />
              </Route>
              <Route path='models'>
                <Route
                  index
                  element={<RequireAuth element={<SolutionsModels />} />}
                />
                <Route
                  path=':modelToken/*'
                  element={<RequireAuth element={<SolutionModelItem />} />}
                />
              </Route>
            </Route>
            <Route
              path='guide'
              element={<RequireAuth element={<SolutionGuide />} />}
            />
            <Route
              path='resource'
              element={<RequireAuth element={<SolutionResourceUsage />} />}
            />
            <Route
              path='settings'
              element={<RequireAuth element={<SolutionsSettings />} />}
            />
          </Route>
        </Route>
        <Route path='projects'>
          <Route
            index
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Projects />} />}
          />
          <Route
            path=':token/*'
            element={<RequireAuth element={<ProjectsItem />} />}
          >
            <Route
              path=':activeitem/*'
              element={<RequireAuth element={<ProjectsItem />} />}
            />
          </Route>
        </Route>
        <Route
          path='smtrejudge/:template_token'
          loader={createLoaderWithRedirect()}
          element={<RequireAuth element={<SMTrejudgeLayout />} />}
        >
          <Route
            path='import'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SMTrejudgeCreate />} />}
          />
          <Route
            path='stations'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SMTrejudgeStations />} />}
          />
          <Route
            path='jobs'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SMTJob />} />}
          />
          <Route
            path='monitor'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Monitor />} />}
          />
          <Route
            path='setting'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SMTSetting />} />}
          />
          <Route
            path='overall'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<SMTOverall />} />}
          />
          <Route
            path='train'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Train />} />}
          >
            <Route path='solutions/:token/:version/models'>
              <Route
                index
                loader={createLoaderWithRedirect()}
                element={<RequireAuth element={<Model />} />}
              />
              <Route
                path=':modelToken/*'
                loader={createLoaderWithRedirect()}
                element={<RequireAuth element={<SolutionModelItem />} />}
              />
            </Route>
          </Route>
        </Route>

        <Route
          path='smtrejudge/:template_token/stations/:solution_name/:solution_token/detail'
          loader={createLoaderWithRedirect()}
          element={<RequireAuth element={<SMTDetailUI />} />}
        />
        <Route
          path='IPLAS/projects/:token/jobs/:jobName/result'
          loader={createLoaderWithRedirect()}
          element={<JobResult />}
        />

        <Route path='datasets'>
          <Route
            index
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Datasets />} />}
          />
          <Route
            path='label_platform/merge_datasets'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<MergeDataset />} />}
          />
          <Route
            path=':token'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<DatasetBody />} />}
          />
        </Route>
        <Route
          path='deploy'
          loader={createLoaderWithRedirect()}
          element={<RequireAuth element={<Deploy />} />}
        />
        <Route
          path='subscription'
          loader={createLoaderWithRedirect()}
          element={
            <RequireAuth
              element={
                <SubscriptionPage entry={SUBSCRIPTION_ENTRY_TYPE.SETTING} />
              }
            />
          }
        >
          <Route
            index
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Subscription />} />}
          />
          <Route
            path='group_quota'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Storage_Quota />} />}
          />
          <Route
            path='billing'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<Billing />} />}
          />
          <Route
            path='license'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<ListLicense />} />}
          />
          <Route
            path='license/activation'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<ListActivation />} />}
          />
        </Route>
        <Route path='solutions'>
          <Route index element={<Navigate to='list/' replace />} />
          <Route path='list'>
            <Route index element={<Navigate to='shop' replace />} />
            <Route
              path='my'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
            <Route
              path='all'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth element={<Solutions />} />}
            />
          </Route>
        </Route>
        <Route
          path='backend'
          loader={createLoaderForDefaultRoute()}
          element={<RequireAuth admin={true} element={<AdminDashboard />} />}
        >
          <Route
            index
            loader={createLoaderForDefaultRoute()}
            element={
              <RequireAuth admin={true} element={<UserUsageDownload />} />
            }
          />
          <Route
            path='users'
            element={<RequireAuth admin={true} element={<Users />} />}
          />
          <Route
            path='auth'
            element={<RequireAuth admin={true} element={<Auth />} />}
          />
          <Route
            path='email'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth admin={true} element={<Email />} />}
          />
          <Route
            path='device'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth admin={true} element={<DeviceInfo />} />}
          />
          <Route
            path='projects'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth admin={true} element={<BackendProjects />} />}
          />
          <Route
            path='subscription'
            loader={createLoaderWithRedirect()}
            element={
              <RequireAuth
                admin={true}
                element={
                  <SubscriptionPage entry={SUBSCRIPTION_ENTRY_TYPE.BACKEND} />
                }
              />
            }
          >
            <Route
              index
              loader={createLoaderWithRedirect()}
              element={<RequireAuth admin={true} element={<Subscription />} />}
            />
            <Route
              path='billing'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth admin={true} element={<Billing />} />}
            />
            <Route
              path='license'
              loader={createLoaderWithRedirect()}
              element={<RequireAuth admin={true} element={<ListLicense />} />}
            />
            <Route
              path='license/activation'
              loader={createLoaderWithRedirect()}
              element={
                <RequireAuth admin={true} element={<ListActivation />} />
              }
            />
          </Route>
          <Route
            path='package'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth admin={true} element={<Package />} />}
          />
          <Route
            path='serving'
            loader={createLoaderWithRedirect()}
            element={
              <RequireAuth admin={true} element={<Serving theme='dark' />} />
            }
          />
          <Route
            path='ai_assistant'
            element={<RequireAuth admin={true} element={<AIAssistant />} />}
          />
          <Route
            path='usage_stats'
            element={<RequireAuth admin={true} element={<UsageStats />} />}
          />
          <Route
            path='contact_us'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth admin={true} element={<ContactUs />} />}
          />
          <Route
            path='feedback_management'
            element={
              <RequireAuth admin={true} element={<FeedbackManagement />} />
            }
          />
        </Route>
        <Route path='playground'>
          <Route
            index
            loader={createLoaderWithRedirect()}
            element={<RequireAuth guest={true} element={<PlaygroundList />} />}
          />
          <Route
            path='collection'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth guest={true} element={<PlaygroundList />} />}
          />
          <Route
            path='manage'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth element={<ListApplication />} />}
          />
          <Route
            path=':playgroundId'
            loader={createLoaderWithRedirect()}
            element={<RequireAuth guest={true} element={<Application />} />}
          />
        </Route>
        <Route
          path='digitalassistant/ai_assistant/*'
          element={
            <RequireAuth guest={true} element={<DigitalAssistantRoute />} />
          }
        />
        <Route
          path='promo_videos'
          loader={createLoaderWithRedirect()}
          element={<RequireAuth guest={true} element={<PromotionalVideos />} />}
        />
        <Route
          path='user_settings'
          element={
            <RequireAuth guest={true} element={<User_Settings_Page />} />
          }
        />
        <Route
          path='*'
          loader={createLoaderWithRedirect()}
          element={<Navigate to='/' />}
        />
      </Route>
      <Route path='/login' element={<LDAPLogin />} />
      <Route path='/signup' element={<LDAPSignUp />} />
      <Route path='/logout' element={<LDAPLogout />} />
      <Route
        path='/dashboard/:_scope/:_alias/:_token'
        loader={() => {
          if (!window.ENABLE_GRAFANA_DASHBOARD) return redirect('/');
          return null;
        }}
        element={<RequireAuth element={<GrafanaDashboard />} />}
      />
    </Route>,
  ),
);

export default PageRouter;
