import React, { useEffect, useState } from 'react';
import style from './AlertManager.module.css';
import { useTranslation } from 'react-i18next';

const AlertManager = () => {
  const alertManagerUrl = `http://${window.location.hostname}:30135/logs?nav=false`;
  const [isNotFound, setIsNotFound] = useState(false);

  const { t } = useTranslation('smt_rejudge');

  useEffect(() => {
    fetch(alertManagerUrl)
      .then((response) => {
        if (response.ok) {
          setIsNotFound(false);
        }
      })
      .catch(() => {
        setIsNotFound(true);
      });
  }, [alertManagerUrl]);

  return isNotFound ? (
    <span className={style.alertMgrNotFound}>
      {t('alert_manager_not_found')}
    </span>
  ) : (
    <iframe src={alertManagerUrl} className={style.alertMgr} />
  );
};

export default AlertManager;
