exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1NV-D78_PPH40sQYnQPNS_ {\n  display: block;\n  text-align: center;\n  margin-top: 12px;\n}\n\n._1YjnqYHr5tANIQal7wsaPj {\n  width: 100%;\n  height: calc(100vh - 7rem);\n  overflow-y: auto;\n}\n", ""]);

// exports
exports.locals = {
	"alertMgrNotFound": "_1NV-D78_PPH40sQYnQPNS_",
	"alertMgr": "_1YjnqYHr5tANIQal7wsaPj"
};