import React, {useEffect, useState, useReducer} from 'react'
import { Col, Row } from 'react-bootstrap'
import { httpGetDeviceInfo } from '../api/edgeRuntime'
import classes from "./DeviceInfo.module.css"
import useHttp from '../CustomHook/use-http'
import { getSystemConfig } from '../utils/common_utils'
import Loading from '../UI/Loading'
import { useDispatch } from 'react-redux';
import { setDeviceEdgeInfo } from '../store/deviceInfoSlice'
import { httpCollectSystemLogStatus, httpCollectSystemLog, httpDownloadSystemLog } from '../api/cambrianService'
import Modal, {modalReducer, defaultModalData} from '../UI/Modal';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { downloadBlobFile, readResponseBlobAsync } from '../common'

const EdgeDeviceInfo = () => {

    const {t} = useTranslation()

    const runtimeVersion = getSystemConfig("RUNTIME_VERSION")
    const dispatch = useDispatch()
    const [modalData, dispatchModalData] = useReducer(modalReducer, defaultModalData)

    const closeModalHandler = (e) => {
        dispatchModalData({type: "data", val: defaultModalData})
    }

    const { sendRequest: reqGetDeviceInfo,
        status: statusGetDeviceInfo,
        data: resGetDeviceInfo,
        error: errGetDeviceInfo,
        isFetching: fetchingGetDeviceInfo
    } = useHttp(httpGetDeviceInfo);

    const {
        sendRequest: reqGetCollectLogStatus,
        error: errorGetCollectLogStatus,
        status: statusGetCollectLogStatus,
        data: resGetCollectLogStatus
    } = useHttp(httpCollectSystemLogStatus);

    const {
        sendRequest: reqDownloadSystemLog,
        error: errorDownloadSystemLog,
        status: statusDownloadSystemLog,
        data: dataDownloadSystemLog
    } = useHttp(httpDownloadSystemLog);

    const {
        sendRequest: reqGetCollectLog,
        error: errorGetCollectLog,
        status: statusGetCollectLog
    } = useHttp(httpCollectSystemLog);

    const [deviceInfo, setDeviceInfo] = useState({OS_ver: "N/A",
                                            CPU_model: "N/A",
                                            RAM_GB: 0,
                                            HDD_GB: 0,
                                            GPU_driver_ver: "N/A",
                                            GPU_RAM_GB: "0",
                                            GPUs: [],
                                            Utilization: {
                                                cpu: 0,
                                                gpu: 0,
                                                gram: 0,
                                                hdd: 0,
                                                ram: 0
                                            }
                                        })

    useEffect(() => {
        reqGetDeviceInfo()
    },[])

    const grafana_monitoring_service = window.location.origin.match(/^(.*:\/\/[^:]+)(?::|$)/)[1] + ":30001";

    const MonitorEmbedURL = {
        CPUUsage: grafana_monitoring_service + "/d-solo/History_System_Resource_uid/history-of-system-resource?orgId=1&refresh=5s&from=now-1h&to=now&panelId=37",
        MemoryUsage: grafana_monitoring_service + "/d-solo/History_System_Resource_uid/history-of-system-resource?orgId=1&refresh=5s&from=now-1h&to=now&panelId=44",
        StorageUsage: grafana_monitoring_service + "/d-solo/History_System_Resource_uid/history-of-system-resource?orgId=1&from=now-1h&to=now&panelId=158&refresh=5s",
        // GPU monitoring panels
        GpuMemUsage: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=42",
        GpuMemUsed: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=60",
        GpuUtilization: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=57",
        GpuPowerUsage: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=24",
        GpuTemperature: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=25",
        GpuMemCopyUtilization: grafana_monitoring_service + "/d-solo/pega_ai_gpu_monitoring_uid/pega_ai_gpu_monitoring?orgId=1&refresh=5s&from=now-1h&to=now&panelId=39",
    };

    useEffect(()=>{
        if (errGetDeviceInfo) {
            toastr.error(`Error get hardware info: ${errGetDeviceInfo}`)
        }else if (statusGetDeviceInfo == 'success') {
            if(resGetDeviceInfo) {
                setDeviceInfo(resGetDeviceInfo)
                dispatch(setDeviceEdgeInfo({
                    GPU_driver_ver: resGetDeviceInfo?.GPU_driver_ver,
                    GPUs: resGetDeviceInfo?.GPUs
                }))
            }
        }
    },[statusGetDeviceInfo, resGetDeviceInfo, errGetDeviceInfo])


    const downloadSystemLog = (e) => {
        e.preventDefault()
        reqGetCollectLogStatus()
    }

    const BodyConfirmDownload = (props) => {
        const {logInfo, onCancel, onDownload, onRepack} = props

        let status = "It has a log file on the server. Do you want to download this file"

        if(logInfo.start_time && logInfo.end_time){
            const formatDate = "YYYY-MM-DD HH:mm:ss"

            let startTime = moment.unix(logInfo.start_time).format(formatDate)
            let endTime = moment.unix(logInfo.end_time).format(formatDate)
            status = `It has a log file on the server from ${startTime} to ${endTime}. Do you want to download this file?`
        }
        return(
            <React.Fragment>
                <div>
                    {status}
                </div>
                <div  style={{marginTop: "50px", display: "flex", justifyContent: "space-between"}}>
                    <button className="btn btn__outline--dark" onClick={onDownload}>
                        Yes
                    </button>
                    <button className="btn btn__outline--dark" onClick={onRepack}>
                        No, I want to download new log
                    </button>
                    <button className="btn btn__outline--dark" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </React.Fragment>
        )
    }

    const downloadLog = () => {

        reqGetCollectLog()

        informCollectingLog()
    }

    const confirmDownload = (logInfo) => {

        if(!logInfo.file_path || logInfo.file_path == ""){
            downloadLog()
        }else{

            dispatchModalData({type: "data", val: {isOpenModal: true,
                body: <BodyConfirmDownload logInfo={logInfo} onDownload={() => {reqDownloadSystemLog(); closeModalHandler()}} onRepack={downloadLog} onCancel={closeModalHandler}/>,
                title: t("button.confirm"),
                mode: "plain",
                onConfirm: "download file",
                textClose: "No, I want new log",
                textConfirm: "Yes"
            }})
        }
    }

    const informIsCollecting = () => {

        dispatchModalData({type: "data", val: {isOpenModal: true,
            body: `The server is collecting the log. You can retry later` ,
            title: "",
            mode: "default",
            onClose: closeModalHandler,
            textClose: t("button.close"),
        }})
    }

    const informCollectingLog = () => {

        dispatchModalData({type: "data", val: {isOpenModal: true,
            body: `It takes time for server to collecting the log. You can come back later to download it` ,
            title: "",
            mode: "default",
            onClose: closeModalHandler,
            textClose: t("button.close"),
        }})
    }

    useEffect(()=>{
        if (errorGetCollectLogStatus) {
            toastr.error(`Error get log collection status`)

        }else if (statusGetCollectLogStatus == 'success') {
            if(resGetCollectLogStatus.is_collecting){
                informIsCollecting()
            }else{
                confirmDownload(resGetCollectLogStatus)
            }
        }
    },[statusGetCollectLogStatus, resGetCollectLogStatus, errorGetCollectLogStatus])

    useEffect(()=>{
        if (errorGetCollectLog) {
            const errorInfo = JSON.parse(errorGetCollectLog)
            if(errorInfo.status == 429){
                toastr.error(`Error: Only 1 request allowed`)
            }
        }else if (statusGetCollectLog == 'success') {
            reqDownloadSystemLog()
        }
    },[errorGetCollectLog, statusGetCollectLog])


    useEffect(()=>{
        if (errorDownloadSystemLog) {
            let errorInfo = JSON.parse(errorDownloadSystemLog)
            toastr.error(`Error download system log file: ${errorInfo.error.message ? errorInfo.error.message : errorInfo.error.detail ? errorInfo.error.detail : 'Internal server error'}`)

        }else if (statusDownloadSystemLog == 'success') {
            /*
            const filename = dataDownloadSystemLog.headers.get('Content-Disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .replace(/"/g, '')
                .trim()
            ;
            */

            readResponseBlobAsync(dataDownloadSystemLog).then(resp => {
                const dateTimeNow = moment().format( "YYYY-MM-DD HH:mm:ss" )

                const filename = `PEGA-AI-${dateTimeNow}.tar.xz`
                const file = new File([resp], filename)

                downloadBlobFile(filename, file);
            })


        }
    },[statusDownloadSystemLog, dataDownloadSystemLog, errorDownloadSystemLog])

    if(fetchingGetDeviceInfo){
        return (<Loading />)
    }else{
        return(
            <React.Fragment>
                <div className={`card info__card--light ${classes['wrapper']}`}>
                    <div className={`${classes['body--wrapper']} ${classes['body--wrapper--device']}`}>
                        <div style={{display: "flex", justifyContent: "end",  "paddingRight": "calc(1.5rem * .5)"}}>
                            <a href="" onClick={downloadSystemLog}>
                                Download System Log
                            </a>
                        </div>
                        <Row className={classes['body--row']}>
                            <Col className={`card info__card--default ${classes['card--info']}`}>
                                <div className={classes['info-content-wrapper']}>
                                    <table className={classes['tb-device-info']}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "200px"}}>
                                                    <label>Runtime ver.</label>
                                                </td>
                                                <td style={{width: "10px"}}>
                                                    :
                                                </td>
                                                <td>
                                                    {runtimeVersion}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>OS</label>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {deviceInfo.OS_ver}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>CPU</label>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {deviceInfo.CPU_model}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{paddingTop: "20px"}}>
                                                    <div className="row" style={{display: "flex", justifyContent: "space-between", paddingTop: "20px", borderTop: "1px solid #574040"}}>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.CPUUsage} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.MemoryUsage} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                        <iframe src={MonitorEmbedURL.StorageUsage} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{paddingTop: "20px"}}>
                                                    <div className="row" style={{display: "flex", justifyContent: "space-between", paddingTop: "20px", borderTop: "1px solid #574040"}}>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.GpuMemUsage} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.GpuMemUsed} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.GpuUtilization} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{paddingTop: "20px"}}>
                                                    <div className="row" style={{display: "flex", justifyContent: "space-between", paddingTop: "20px", borderTop: "1px solid #574040"}}>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.GpuPowerUsage} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                            <iframe src={MonitorEmbedURL.GpuTemperature} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                        <div className={`col`}>
                                                        <iframe src={MonitorEmbedURL.GpuMemCopyUtilization} width="100%" height="250" frameborder="0"></iframe>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {modalData.isOpenModal &&
                    <Modal
                        {...modalData}
                    />
                }

            </React.Fragment>
        )
    }
}

export default EdgeDeviceInfo